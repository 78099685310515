<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-card>
            <v-toolbar dark color="blue" class="darken-1">
                <v-toolbar-title>{{$t('upload_immigration_from_excel')}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-snackbar v-model="snackbar"
                        color="error"
                        :timeout="callbackResponse.timeout"
                        top>
                {{ callbackResponse.message }}
            </v-snackbar>
            <v-card-text>
                <v-container>
                    <form class="fix-for-nepali-label">
                        <v-file-input :error-messages="imageError"
                                      placeholder="Image"
                                      dense
                                      outlined
                                      prepend-inner-icon="mdi-microsoft-excel"
                                      class="pl-2"
                                      required
                                      v-model="editedData.excelFile">
                            <template v-slot:label>
                                {{$t('select_excel_file')}}
                            </template>
                        </v-file-input>
                    </form>
                </v-container>
            </v-card-text>
            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn @click.stop="onDownload" class="success">
                    <v-icon>get_app</v-icon>
                    {{$t('download_sample')}}
                </v-btn>
                <v-btn dark color="blue darken-1" @click="submit">{{ $t("import") }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-content>
    
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createHouseForm",
        props: ["success"],

        computed: {
            imageError() {
                const errors = [];
                if (!this.$v.editedData.excelFile.$dirty) return errors;
                !this.$v.editedData.excelFile.required && errors.push("Image is required.");
                return errors;
            },
            items() {
                return [
                    {
                        text: this.$t('import_immigration_raw_data'),
                        exact: true,
                        to: '/ImmigrationRawDataImport'
                    },
                    {
                        text: this.$t('import_immigration_raw_excel_data'),
                        disabled: true,
                        exact: true
                    }
                ]
            },
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000,
                },
                returnData: [],
                houseType: [],
                roomType: [],
                show: false,
                updated: false,
                snackbar: false,
                editedData: {
                    excelFile: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: "",
                },
            };
        },
        validations: {
            editedData: {
                excelFile: { required },
            },
        },
        destroyed() { },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData);
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                //axios.get('Hotel/RoomList').then((response) => {
                //    this.roomType = response.data;
                //})
            },
            async onDownload() {
                await axios({
                    url: 'Immigration/SampleDownload',
                    method: 'POST',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImmigrationRawDataSample.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    var form = new FormData();
                    form.append("ExcelFile", this.editedData.excelFile);
                    axios
                        .post("Immigration/ImmigrationRawDataImport", form)
                        .then((response) => {
                            if (response.data.success) {
                                this.editedData = {};
                                this.servicesData.data = response.data;
                                this.servicesData.message = "Excel File Uploaded Successfully";
                                this.servicesData.color = "success";
                                this.dialogueClose();
                            } else {
                                this.snackbar = true;
                                this.callbackResponse.message = response.data.errors[0];
                            }
                        })
                        .catch((response) => {
                            this.servicesData.data = response.data;
                            this.servicesData.message =
                                "Error Adding Service, Please Contact Admin";
                            this.servicesData.color = "error";
                            this.dialogueClose();
                        });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.roomCategoryID = "";
            },
        },
    };
</script>

<style scoped></style>
